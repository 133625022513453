import { Col } from "react-bootstrap";
import React from 'react';

export const ProjectCard = ({ title, description, imgUrl,url }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx" >
        <img src={imgUrl}  style={{"height":"15rem"}}/>
        <div className="proj-txtx">
          <h4>{title}</h4>
          
          <span>{url}</span>
          <br/>
          <h5>{description}</h5>
        </div>
      </div>
    </Col>
  )
}
