import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/suretrust.png";
import projImg2 from "../assets/img/canco.png";
import projImg3 from "../assets/img/myalgo.png";
import projImg4 from "../assets/img/markchem.png";
import projImg5 from "../assets/img/ipl.png";
import projImg6 from "../assets/img/Movie.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import React from 'react';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Startup",
      description: "App & Web Development",
      url : "https://suretrustforruralyouth.com/",
      imgUrl: projImg1,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      url : "https://dev.cancopetroleum.ca/",
      imgUrl: projImg2,
    },
    {
      title: "Business",
      description: "Design & Development",
      url : "http://62.72.59.202:8000/",
      imgUrl: projImg3,
    },
    {
      title: "Business",
      description: "App  Development",
      url : "https://play.google.com/store/apps/details?id=org.markchem.app",
      imgUrl: projImg4,
    },
    {
      title: "Business Startup",
      description: "App  Development",
      url : "https://play.google.com/store/apps/details?id=com.livescore.livecricketscore.newapps&pcampaignid=web_share",
      imgUrl: projImg5,
    },
    {
      title: "Business Startup",
      description: "App Development",
      url : "https://play.google.com/store/apps/details?id=com.hdmovie.moviedownloader.allmoviedownload",
      imgUrl: projImg6,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Explore a showcase of our client-centric projects, each meticulously crafted to meet unique needs. From innovative startups to established businesses, witness the diverse range of solutions that define our commitment to excellence. Your vision is our mission, and these projects embody our passion for delivering unparalleled results</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 " id="pills-tab">
                  {/* justify-content-center align-items-center */}
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane> */}
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
